import { Autocomplete, Box, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material"

import { USER_ROLES } from "../../utils/constants"
import { CAMP_STATUS, SEVEARITY_OF_LOSS, TYPE_OF_HEARING_LOSS, TYPE_OF_HEARING_LOSS_LIST, WAY_OF_CONFIRMATION } from "../../utils/beneficiary.constant"
import CreatableDropDownComponent from "../../components/custom/CreateableDropodown"
import { getTeachersApi } from "../../apis/user.api"
import CreateController from "../user/CreateController"
import CampCreateController from "../camp/CreateController"
import { getCampApi } from "../../apis/camp.api"
import { useSelector } from "react-redux"
import MODULES from "../../utils/module.constant"
import { findObjectKeyByValue, titleCase } from "../../utils/helper"
import CustomInput from "../../components/inputs/CustomInput"
import FileInput from "../../components/inputs/FileInput"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getProductApi } from "../../apis/product.api"

const TestDetailsFields = ({ show, fields, setFields, loading }) => {
    const { user } = useSelector(state => state)
    return <>
        <Box sx={{ width: "100%", display: show ? "block" : "none" }}>
            <Grid container spacing={2}>
            <Grid item xs={12} >
                    <FormControl margin="dense" fullWidth >
                        <AsyncDropDown
                            label="Tested Product*"
                            onlyDropDown={!user.data?.modules?.includes?.(MODULES.PRODUCTS)}
                            lazyFun={async (para) => await getProductApi({ ...para })}

                            defaultVal={typeof fields.product == "object" ? fields.product : null}
                            onChange={async (changedVal) => {
                                setFields({ ...fields, product: changedVal ? changedVal : null, })
                            }}
                            titleKey={'name'}
                            valueKey={"_id"}

                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} >
                    <Autocomplete
                        value={findObjectKeyByValue(fields.type_of_hearing_loss, TYPE_OF_HEARING_LOSS)}
                        disableClearable
                        onChange={(e, changedVal) => { setFields({ ...fields, type_of_hearing_loss: changedVal ? changedVal._id : null, }) }}
                        options={TYPE_OF_HEARING_LOSS_LIST}

                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput label="Type Of Hearing Loss*" {...params} />}
                    />

                </Grid>
                <Grid item xs={12} sm={6} >
                    <Autocomplete
                        value={findObjectKeyByValue(fields.severity_of_hearing_loss, SEVEARITY_OF_LOSS)}
                        disableClearable
                        onChange={(e, changedVal) => { setFields({ ...fields, severity_of_hearing_loss: changedVal ? changedVal._id : null, }) }}
                        options={Object.keys(SEVEARITY_OF_LOSS).map((key) => ({ label: titleCase(key), _id: SEVEARITY_OF_LOSS[key] }))}

                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput label="Severity Of Hearing Loss*" {...params} />}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={3} >
                    <FormControl fullWidth>
                        <FormLabel >Device Response?</FormLabel>
                        <RadioGroup
                            row
                            value={fields.nu_device_responce}
                            onChange={(e) => {
                                setFields({ ...fields, nu_device_responce: e.target.value })
                            }}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />

                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} >

                    <FormControl fullWidth margin="dense">
                        <FormLabel >Music Response?</FormLabel>
                        <RadioGroup
                            row
                            value={fields.music_responce}
                            onChange={(e) => {
                                setFields({ ...fields, music_responce: e.target.value })
                            }}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />

                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                    <FormControl fullWidth margin="dense">
                        <FormLabel >Voice Response?</FormLabel>
                        <RadioGroup
                            row
                            value={fields.voice_responce}
                            onChange={(e) => {
                                setFields({ ...fields, voice_responce: e.target.value })
                            }}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />

                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3} >
                    <FormControl fullWidth margin="dense">
                        <FormLabel >Has Smartphone?</FormLabel>
                        <RadioGroup
                            row
                            value={fields.has_smart_phone}
                            onChange={(e) => {
                                setFields({ ...fields, has_smart_phone: e.target.value })
                            }}
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Yes" />
                            <FormControlLabel value={false} control={<Radio />} label="No" />

                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} >
                    <Autocomplete
                        value={findObjectKeyByValue(fields.way_of_confirmation, WAY_OF_CONFIRMATION)}
                        disableClearable
                        onChange={(e, changedVal) => { setFields({ ...fields, way_of_confirmation: changedVal ? changedVal._id : null, }) }}
                        options={Object.keys(WAY_OF_CONFIRMATION).map((key) => ({ label: titleCase(key), _id: WAY_OF_CONFIRMATION[key] }))}

                        sx={{ width: "100%", display: "flex", "*": { display: "flex", justifyContent: "center" } }}
                        renderInput={(params) => <CustomInput label="Way Of Confirmation*" {...params} />}
                    /></Grid>
              
                {!(fields.nu_device_responce == 'false' && fields.voice_responce == 'false' && fields.music_responce == 'false') && <>
                    <Grid item xs={12} sm={6} >
                        <CustomInput

                            disabled={loading}
                            value={fields.left_response_in_db}
                            onChange={(e) => setFields({ ...fields, err: '', left_response_in_db: e.target.value })}
                            type="number"
                            label={"Left Ear Response(dB)*"}

                        />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <CustomInput

                            disabled={loading}
                            value={fields.right_response_in_db}
                            onChange={(e) => setFields({ ...fields, err: '', right_response_in_db: e.target.value })}
                            type="number"
                            label={"Right Ear Response(dB)*"}

                        />
                    </Grid>
                </>}


                <Grid item xs={12} >
                    <FileInput
                        defaults={fields.audio_gram_file ? [fields.audio_gram_file] : undefined}
                        title="Audiogram (if Available)"
                        subTitle="Only .pdf less than 2 mb is required."
                        accept=".pdf"
                        onChange={(newUrl) => {
                            setFields({ ...fields, audio_gram_file: newUrl })
                        }} />
                </Grid>

            </Grid>
        </Box>
    </>
}
export default TestDetailsFields