import {
  Autocomplete,
  ListItem,
  Grid,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  Skeleton,
  Typography,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";

import { useSelector } from "react-redux";
import DataTable from "../../../components/tables/DataTable";
import PaddingBoxInDesktop from "../../../components/layouts/PaddingBoxInDesktop";
import { StyledSearchBar } from "../../../components/inputs/SearchBar";
import TimeRangeSelector from "../../../components/layouts/common/TimeRangeSelector";
import NoDataComponent from "../../../components/layouts/NoDataComponent";
import { findObjectKeyByValue, titleCase } from "../../../utils/helper";
import { REGIONS_TYPE, USER_ROLES } from "../../../utils/constants";
import AsyncDropDown from "../../../components/inputs/AsyncDropDown";
import { getRegionApi } from "../../../apis/region.api";
import { CenteredBox } from "../../../components/layouts/OneViewBox";

import SubmitButton from "../../../components/button/SubmitButton";
import { getEventApi, getRegionByEventApi } from "../../../apis/event.api";
import { getDonorsApi } from "../../../apis/user.api";
import { getCampApi } from "../../../apis/camp.api";
import { getProductApi } from "../../../apis/product.api";

const FiltersBox = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",

  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const CountBox = styled(Box)(({ theme, active }) => ({
  background: theme.palette.light.main,
  border: "1px solid " + theme.palette.grey.main,
  height: "100%",

  width: "100%",
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
}));
const CountBoxContainer = styled(Box)(({ theme, active }) => ({
  background: theme.palette.secondary.main,
  border: "1px solid " + theme.palette.grey.main,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
}));

const RegionBoxComponent = ({ donated, count, title, active }) => {
  let donatedCount = 0;
  let notDonatedCount = 0;
  if (donated && Array.isArray(donated)) {
    for (let item of donated) {
      if (item.donated) {
        donatedCount += item.count;
      }
      if (!item.donated) {
        notDonatedCount += item.count;
      }
    }
  }
  return (
    <CountBoxContainer active={active} sx={{ width: "100%" }}>
      <Typography variant="h6" fontWeight="bold" align="left" mb={1}>
        {title}
      </Typography>
      <Grid container>
        <Grid item xs={4}>
          <CountBox>
            <Typography variant="h4" fontWeight="bold" align="center">
              {count}
            </Typography>
            <Typography variant="h6" fontWeight="bold" align="center">
              Total
            </Typography>
          </CountBox>
        </Grid>
        <Grid item xs={4}>
          <CountBox>
            <Typography variant="h4" fontWeight="bold" align="center">
              {donatedCount}
            </Typography>
            <Typography variant="h6" fontWeight="bold" align="center">
              Donated
            </Typography>
          </CountBox>
        </Grid>
        <Grid item xs={4}>
          <CountBox>
            <Typography variant="h4" fontWeight="bold" align="center">
              {notDonatedCount}
            </Typography>
            <Typography variant="h6" fontWeight="bold" align="center">
              Awaited
            </Typography>
          </CountBox>
        </Grid>
      </Grid>
    </CountBoxContainer>
  );
};

const ListOfBeneficiaryByRegionUi = ({
  title,
  filters,
  setFilters,
  list,
  loading,
  modal,
  downloadPdf,
  downloadLoading,
}) => {
  const user = useSelector((state) => state.user);

  return (
    <>
      <Box mb={3}>
        <Paper
          elevation={modal ? 0 : 2}
          sx={{ width: "100%", padding: modal ? 0 : 4 }}
        >
          {!modal && (
            <Box mb={4}>
              <FiltersBox mb={3}>
                <PaddingBoxInDesktop
                  sx={{
                    display: "flex",
                    flex: 1,
                    "*": {
                      justifyContent: "center",
                    },
                  }}
                >
                  <Typography variant="h5" mb={2}>
                    {title}
                  </Typography>
                </PaddingBoxInDesktop>
              </FiltersBox>
              <FiltersBox mb={2}>
                <PaddingBoxInDesktop
                  mb={2}
                  sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
                >
                       <AsyncDropDown
                        // key={filters.donor}
                        lazyFun={async (para) => {
                          console.log("DONER PARA", para);
                          return await getDonorsApi({ ...para });
                        }}
                        OptionComponent={({ option, ...rest }) => {
                          console.log("DONER OPTIONS", option);
                          return <ListItem {...rest}>{option.name}</ListItem>;
                        }}
                        onChange={async (changedVal) => {
                          console.log("DONOR CHANGE", changedVal);
                          setFilters({
                            ...filters,
                            donor: changedVal ? changedVal._id : null,
                          });
                        }}
                        titleKey={"name"}
                        valueKey={"_id"}
                        InputComponent={(params) => (
                          console.log("donor", params),
                          (
                            <StyledSearchBar
                              placeholder={"Select Donor"}
                              {...params}
                              margin="none"
                            />
                          )
                        )}
                      />
                </PaddingBoxInDesktop>

                <PaddingBoxInDesktop
                  mb={2}
                  sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
                  pl={2}
                >
                  <AsyncDropDown
                    lazyFun={async (para) =>
                      await getProductApi({ ...para, allStatus: true })
                    }
                    OptionComponent={({ option, ...rest }) => {
                      return <ListItem {...rest}>{option.name}</ListItem>;
                    }}
                    onChange={async (changedVal) => {
                      setFilters({
                        ...filters,
                        product: changedVal ? changedVal._id : null,
                      });
                    }}
                    titleKey={"name"}
                    valueKey={"_id"}
                    InputComponent={(params) => (
                      <StyledSearchBar
                        placeholder={"Select Product"}
                        {...params}
                        margin="none"
                      />
                    )}
                  />
                </PaddingBoxInDesktop>
                <PaddingBoxInDesktop
                  mb={2}
                  sx={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    "*": {
                      justifyContent: "center",
                    },
                  }}
                  pl={2}
                >
                  <AsyncDropDown
                    // key={filters.donation_event_id}
                    lazyFun={async (para) => {
                      return await getEventApi({ ...para });
                    }}
                    OptionComponent={({ option, ...rest }) => {
                      return <ListItem {...rest}>{option.name}</ListItem>;
                    }}
                    onChange={async (changedVal) => {
                      setFilters({
                        ...filters,
                        donation_event_id: changedVal ? changedVal._id : null,
                      });
                    }}
                    titleKey={"name"}
                    valueKey={"_id"}
                    InputComponent={(params) => (
                      <StyledSearchBar
                        placeholder={"Select Event"}
                        {...params}
                        margin="none"
                      />
                    )}
                  />
                </PaddingBoxInDesktop>
              </FiltersBox>
              <FiltersBox>
                <PaddingBoxInDesktop
                  sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}
                >
                  <Autocomplete
                    disableClearable
                    value={
                      filters.type
                        ? findObjectKeyByValue(filters.type, REGIONS_TYPE)
                        : null
                    }
                    onChange={(e, newVal) => {
                      setFilters({
                        ...filters,
                        type: newVal ? newVal._id : null,
                        parent: null,
                      });
                    }}
                    options={[
                      ...Object.keys(REGIONS_TYPE).map((key) => ({
                        label: titleCase(key),
                        _id: REGIONS_TYPE[key],
                      })),
                    ]}
                    sx={{
                      width: "100%",
                      display: "flex",
                      "*": {
                        display: "flex",
                        justifyContent: "center",
                        flex: 1,
                      },
                    }}
                    renderInput={(params) => (
                      <StyledSearchBar
                        placeholder="Select Region Type*"
                        {...params}
                      />
                    )}
                  />
                </PaddingBoxInDesktop>
                {/* {user.data.role != USER_ROLES.ADMIN &&
                 (
                  <>
               
                            <PaddingBoxInDesktop sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }} pl={3}  >
                            <AsyncDropDown


                                lazyFun={getEventApi}

                                OptionComponent={({ option, ...rest }) => {
                                    return <ListItem {...rest}>{option.name}</ListItem >
                                }}
                                onChange={async (changedVal) => {
                                    setFilters({ ...filters, donation_event_id: changedVal ? changedVal._id : null, })
                                }}
                                titleKey={'name'}
                                valueKey={"_id"}
                                InputComponent={(params) => <StyledSearchBar placeholder={"Select Event"} {...params} margin="none" />}
                            />
                        </PaddingBoxInDesktop>
                        </>
                         )} */}

                {filters.type &&
                  filters.type != REGIONS_TYPE.COUNTRY &&
                  (user.data.role === USER_ROLES.DONOR ||
                    user.data.role === USER_ROLES.NGO) && (
                    <PaddingBoxInDesktop
                      sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                        "*": {
                          justifyContent: "center",
                        },
                      }}
                      pl={3}
                    >
                      <AsyncDropDown
                        key={filters.type}
                        lazyFun={async (para) => {
                          return await getRegionByEventApi({
                            type: filters.type - 1,
                            ...para,
                          });
                        }}
                        OptionComponent={({ option, ...rest }) => {
                          return <ListItem {...rest}>{option.name}</ListItem>;
                        }}
                        onChange={async (changedVal) => {
                          setFilters({
                            ...filters,
                            parent: changedVal ? changedVal._id : null,
                          });
                        }}
                        titleKey={"name"}
                        valueKey={"_id"}
                        InputComponent={(params) => (
                          <StyledSearchBar
                            placeholder={
                              "Select " +
                              findObjectKeyByValue(
                                filters.type - 1,
                                REGIONS_TYPE
                              ).toLowerCase()
                            }
                            {...params}
                            margin="none"
                          />
                        )}
                      />
                    </PaddingBoxInDesktop>
                  )}

                {filters.type &&
                  filters.type != REGIONS_TYPE.COUNTRY &&
                  user.data.role != USER_ROLES.DONOR &&
                  user.data.role != USER_ROLES.NGO && (
                    <PaddingBoxInDesktop
                      sx={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                        "*": {
                          justifyContent: "center",
                        },
                      }}
                      pl={3}
                    >
                      <AsyncDropDown
                        key={filters.type}
                        lazyFun={async (para) => {
                          return await getRegionApi({
                            type: filters.type - 1,
                            ...para,
                          });
                        }}
                        OptionComponent={({ option, ...rest }) => {
                          return <ListItem {...rest}>{option.name}</ListItem>;
                        }}
                        onChange={async (changedVal) => {
                          // console.log("CHANVGE",changedVal)
                          setFilters({
                            ...filters,
                            parent: changedVal ? changedVal._id : null,
                          });
                        }}
                        titleKey={"name"}
                        valueKey={"_id"}
                        InputComponent={(params) => (
                          <StyledSearchBar
                            placeholder={
                              "Select " +
                              findObjectKeyByValue(
                                filters.type - 1,
                                REGIONS_TYPE
                              ).toLowerCase()
                            }
                            {...params}
                            margin="none"
                          />
                        )}
                      />
                    </PaddingBoxInDesktop>
                  )}

                <PaddingBoxInDesktop
                  sx={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    "*": {
                      justifyContent: "center",
                    },
                  }}
                  pl={3}
                >
                  <StyledSearchBar
                    fullWidth
                    title="Search By Region Name "
                    size="small"
                    placeholder={"Search By Region Name"}
                    value={filters.search}
                    onChange={(e) => {
                      setFilters({ ...filters, search: e.target.value });
                    }}
                  />
                </PaddingBoxInDesktop>
              </FiltersBox>
            </Box>
          )}
          <FiltersBox>
            <Box mb={2}>
              <SubmitButton
                disableElevation
                onClick={downloadPdf}
                title="Downlaod Report"
                loading={downloadLoading}
              />
            </Box>
          </FiltersBox>
          <Box
            sx={{
              minHeight: "300px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {loading ? (
              <>
                {[0, 1, 2].map((item) => (
                  <Skeleton
                    variant="rounded"
                    height="100px"
                    width="100%"
                    key={item}
                    sx={{ mb: 2 }}
                  />
                ))}
              </>
            ) : (
              <>
                {list && list.length > 0 ? (
                  list.map((val) => {
                    return (
                      <RegionBoxComponent
                        donated={val.donated}
                        title={val.region ? val.region.name : "NA"}
                        count={val.count}
                        key={val.region ? val.region._id : null}
                      />
                    );
                  })
                ) : (
                  <CenteredBox sx={{ display: "flex", flex: 1 }}>
                    <NoDataComponent
                      message={
                        filters.parent || filters.type == REGIONS_TYPE.COUNTRY
                          ? "No Data Found"
                          : "No " +
                            findObjectKeyByValue(
                              filters.type - 1,
                              REGIONS_TYPE
                            ) +
                            " Selected"
                      }
                    />
                  </CenteredBox>
                )}
              </>
            )}
          </Box>
        </Paper>
      </Box>
    </>
  );
};
export default ListOfBeneficiaryByRegionUi;
