import { memo, useCallback, useEffect, useState } from "react"
import ListUi from "./ListOfBeneficiaryByRegion"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"

import { openModal } from "../../../store/actions/modalAction"

import { Chip } from "@mui/material"
import { useMemo } from "react"



import { getCountsByRegionBeneficiaryApi } from "../../../apis/beneficiary.api"
import { BENIFICIARY_STATUS } from "../../../utils/beneficiary.constant"
import { REGIONS_TYPE } from "../../../utils/constants"
import fileDownload from "js-file-download"





const   ListOfBeneficiaryByRegionController = ({ parent, type, modal }) => {
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const title = "Beneficiary By Region"
    const modalKey = 'beneficiary'

    const fetchApi = getCountsByRegionBeneficiaryApi





    const [filters, setFilters] = useState({
        pageNo: 1,
        pageSize: 10,
        search: '',
        searchable: ['name'],
        sort: 'region.name',
        type: type ?? REGIONS_TYPE.COUNTRY,
        parent: parent ?? null,
        donation_event_id:null, 
        donor:null,
        product:null,
        sortDirection: 1,
        validated: true

    })





    // do not change 

    const [loading, setLoading] = useState(false)

    const [downloadLoading, setDownloadLoading] = useState(false)

    const [list, setList] = useState([])








    const fetchList = () => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters }),
            (response) => {
                setList(response)
                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }

    const downloadPdf = () => {
        setDownloadLoading(true)
        dispatch(callApiAction(
            async () => await fetchApi({ ...filters, pdf: true }),
            (response) => {
                console.log("RESPONSE",response);
                fileDownload(response, "list-by-regions.pdf")
                setDownloadLoading(false)
            },
            (err) => {
                setDownloadLoading(false)
            },
            true
        ))
    }



    useEffect(() => {
        if (filters.parent || filters.type == REGIONS_TYPE.COUNTRY)
            fetchList()
        else
            setList([])
    }, [filters])



    return (
        <>
            <ListUi
                downloadLoading={downloadLoading}
                downloadPdf={downloadPdf}
                title={title}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                modal={modal}


            />

        </>
    )
}
export default ListOfBeneficiaryByRegionController