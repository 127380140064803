import { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import ListUi from "./ListVideoUi";

import { callApiAction } from "../../../store/actions/commonAction";

import {
  getBeneficiaryVideoListApi,
  getBeneficiaryVideosCount,
  updateVideoStatusApi,
} from "../../../apis/beneficiary.api";

import { createFullName, findObjectKeyByValue } from "../../../utils/helper";
import { closeModal, openModal } from "../../../store/actions/modalAction";
import VideoViewer from "../../videos/VideoViewer";
import { Draw, Edit, People, VideoCall } from "@mui/icons-material";
import DetailedViewController from "../detailedview/DetailedViewController";
import { Box, Chip, IconButton, Rating, Upgrade } from "@mui/material";
import UpdateVideoUrlModal from "./UpdateVideoUrlModal";
import UpdateVideoRatingModal from "./UpdateVideoRatingModal";
import {
  SNACK_BAR_VARIETNS,
  USER_ROLES,
  VIDEOS_STATUS,
  VIDEOS_TAGS,
} from "../../../utils/constants";
import { findAllByAltText } from "@testing-library/react";
import SubmitButton from "../../../components/button/SubmitButton";
import MessageDilog from "../../../components/MessageDilog";
import UpdateVideoStatusModal from "./UpdateVideoStatusModal";
import { callSnackBar } from "../../../store/actions/snackbarAction";
import RejectedVideoCommentModal from "./RejectedVideoCommentModal";

const ActionComponent = ({ params, setParams }) => {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const approveVideo = () => {
    
    {
      user.data.role == USER_ROLES.SIT_MANAGER
        ? dispatch(
            openModal(
              <UpdateVideoRatingModal
                beneficiary_name={params?.name}
                followup_id={params?._id}
                video_id={params?.videos?._id}
                rating={params?.videos?.ratings}
                tags={params?.videos?.tags}
                date={params?.videos?.date}
                callBack={(fields) => {
                  const video = { ...params?.videos };
                  video["ratings"] = fields?.rating;
                  video["tags"] = fields?.tags;
                  video['date']=fields?.date;
                  console.log("PPPPPPP", video + " " + fields);
                  // setParams({ ...log, videos: video });
                }}
              />,
              "sm",
              undefined,
              "update-video-rating"
            )
          )
        : dispatch(
            openModal(
              <MessageDilog
                title={
                  "Are you sure to update beneficiary video status " +
                  params?.beneficiary_id?.name
                }
                message="On click of Update Status Admin Approved"
                onSubmit={onSubmitApprove}
              />,
              "sm"
            )
          );
    }
  };

  const rejecteVideo = () => {
  dispatch(
      openModal(
        <RejectedVideoCommentModal
          beneficiary_name={params?.beneficiary_id?.name}
          followup_id={params?._id}
          video_id={params?.videos?._id}
          comments={params?.videos?.comments}
          date={params?.videos?.date}
          // console.log("setParams")
          video_status={params?.videos?.video_status}
          callBack={(fields) => {
            const video = { ...params?.videos };
            video["comments"] = fields?.comments;
            video["video_status"] =fields?.video_status
            video['date']=fields?.date
  
          }}
        />,
        "sm",
        undefined,
        "reject-video"
      )
    );
    // }
  };
 
  const followup_id = params?._id;
  const video_id = params?.videos?._id;
  const video_status = params?.videos?.video_status;
  const date = params?.videos?.date;
  // const [loading, setLoading] = useState(false);
  const comments=params?.videos?.comments
  const [fields, setFields] = useState({
    // rating,
    // tags,
    video_id,
    followup_id,
    video_status,
    date,
    comments,
    err: "",
  });
  // const onSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(closeModal())
  //   setLoading(true);
  //   dispatch(
  //     callApiAction(
  //       async () => await updateVideoStatusApi({ ...fields,video_status:VIDEOS_STATUS.ADMIN_REJECT }),
  //       (response) => {
  //         dispatch(
  //           callSnackBar(
  //             "Video Status Updated successfully.",
  //             SNACK_BAR_VARIETNS.suceess
  //           )
  //         );
  //         setLoading(false);
  //         // onclose();
  //         // callBack(fields);
  //       },
  //       (err) => {
  //         setLoading(false);
  //       }
  //     )
  //   );
  // };

  const onSubmitApprove = (e) => {
    e.preventDefault();
    dispatch(closeModal());
    setLoading(true);
    dispatch(
      callApiAction(
        async () =>
          await updateVideoStatusApi({
            ...fields,
            video_status: VIDEOS_STATUS.ADMIN_APPROVE,
            date:new Date().toISOString(),
          }),
        (response) => {
          dispatch(
            callSnackBar(
              "Video Status Updated successfully.",
              SNACK_BAR_VARIETNS.suceess
            )
          );
          setLoading(false);
          // onClose();
          // callBack(fields);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
        {params?.videos?.video_status === VIDEOS_STATUS.ADMIN_REJECT ? (
          <Chip label="Rejected" color="error" sx={{ m: 2, p: 3 }} />
        ) : (
          <>
            <SubmitButton
              title="Approve"
              color="success"
              onClick={() => approveVideo()}
            ></SubmitButton>

            <SubmitButton
              title="Reject"
              color="error"
              onClick={() => rejecteVideo()}
            ></SubmitButton>
          </>
        )}
      </Box>
    </>
  );
};
const VideoViewButton = ({ params, setParams }) => {
  const dispatch = useDispatch();

  const onclick = () => {
    dispatch(
      openModal(
        <VideoViewer params={params} />,
        "md",
        undefined,
        "video-viewer"
      )
    );
  };
  return (
    <IconButton onClick={onclick}>
      {" "}
      <VideoCall />{" "}
    </IconButton>
  );
};
const NameButton = ({ params, setParams }) => {
  const dispatch = useDispatch();

  const onclick = () => {
    dispatch(
      openModal(
        <DetailedViewController id={params?.beneficiary_id?._id} />,
        "md",
        undefined,
        "beneficiary-detailed-view"
      )
    );
  };
  return (
    <IconButton onClick={onclick}>
      <People />
    </IconButton>
  );
};

const EditVideoUrl = ({ params, setParams }) => {
  const dispatch = useDispatch();

  const onclick = () => {
    dispatch(
      openModal(
        <UpdateVideoUrlModal
          followup_id={params?._id}
          video_id={params?.videos?._id}
          url={params?.videos?.url}
          callBack={(fields) => {
            const video = params?.videos;
            video["url"] = fields.url;
            setParams({ ...params, videos: video });
          }}
        />,
        "sm",
        undefined,
        "update-video"
      )
    );
  };
  return (
    <IconButton onClick={onclick}>
      <Draw />
    </IconButton>
  );
};

const VideoRatings = ({ params, setParams }) => {
  const dispatch = useDispatch();

  const onclick = () => {
    dispatch(
      openModal(
        <UpdateVideoRatingModal
          followup_id={params._id}
          video_id={params.videos?._id}
          rating={params.videos?.ratings}
          tags={params.videos?.tags}
          other_tags={params.videos?.other_tags}
          callBack={(fields) => {
            const video = { ...params.videos };
            video["ratings"] = fields.rating;
            video["tags"] = fields.tags;
            video['other_tags'] = fields.other_tags;
            console.log(video, fields);
            setParams({ ...params, videos: video });
          }}
        />,
        "sm",
        undefined,
        "update-video-rating"
      )
    );
  };
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Rating
        name="rating-controlled"
        value={params.videos?.ratings}
        readOnly
      />
    </Box>
  );
};
// };
const ListVideoController = () => {
  const { user } = useSelector((state) => state);
  const dispatch = useDispatch();
  const title = "Beneficiary Raw Videos";
  const modalKey = "beneficiary-row";

  const fetchApi = getBeneficiaryVideoListApi;

  const columns = useMemo(
    () => [
      {
        id: 0,
        fieldName: "videos.date",
        label: "Video Taken Date",
        align: "left",
        sort: true,
        renderValue: (fields) =>
          moment(fields.videos?.date).format("DD/MM/YYYY"),
      },
      {
        id: 1,
        fieldName: "name",
        label: "Name",
        align: "left",
        sort: true,
        renderValue: (params) =>
          createFullName(
            params?.beneficiary_id?.surname,
            params?.beneficiary_id?.name
          ),
      },
      {
        id: 2,
        fieldName: "video",
        label: "Video",
        align: "left",
        renderValue: (params) => <VideoViewButton params={params} />,
      },
      {
        id: 3,
        fieldName: "name",
        label: "Beneficiary Details",
        align: "left",
        renderValue: (params) => <NameButton params={params} />,
      },
      {
        id: 4,
        fieldName: "followup_no",
        label: "Followup No.",
        align: "left",
      },
      {
        id: 5,
        fieldName: "videos.ratings",
        label: "Ratings",
        sort: true,
        align: "left",
        renderValue: (params, setParams) => (
          <Chip
            size="small"
            color="primary"
            label={params?.videos?.ratings + " Star"}
          />
        ),
      },
      {
        id: 6,
        fieldName: "videos.tags",
        label: "Tags",
        sort: true,
        align: "left",
        renderValue: (params, setParams) =>
          (findObjectKeyByValue(params?.videos?.tags, VIDEOS_TAGS) == "Other" ? params?.videos?.other_tags : findObjectKeyByValue(params?.videos?.tags, VIDEOS_TAGS)),
      },

      {
        id: 7,
        fieldName: "videos.comments",
        label: "Comment",
        sort: true,
        align: "left",
        renderValue: (params, setParams) => 
        params?.videos?.comments,
       
      },
          {
        id: 6,
        fieldName: " ",
        label: "Action",
        sort: true,
        align: "left",
        renderValue: (params, setParams) =>
          ((params.videos.video_status === VIDEOS_STATUS.PENDING &&
            user.data.role === USER_ROLES.SIT_MANAGER) ||
            (params.videos.video_status === VIDEOS_STATUS.MANAGER_APPROVE &&
              user.data.role === USER_ROLES.ADMIN)) && (
            <ActionComponent params={params} setParams={setParams} />
          ),
          // }
      },

      user.data.role === USER_ROLES.SIT_TEAM && {
        id: 7,
        fieldName: " ",
        label: "Update Video",
        sort: true,
        align: "left",
        renderValue: (params, setParams) =>
          (params.videos.video_status === VIDEOS_STATUS.ADMIN_REJECT ||
            params.videos.video_status === VIDEOS_STATUS.MANAGER_REJECT) && (
            <EditVideoUrl params={params} setParams={setParams} />
          ),
      },
    ],
    [dispatch]
  );

  const [filters, setFilters] = useState({
    pageNo: 1,
    pageSize: 10,
    search: "",
    searchable: ["name", "udise_no"],
    sort: "videos.date",
    validated: true,
    nuResponse: null,
    donated: null,
    donation_event_id: null,
    camp: null,
    country: null,
    state: null,
    district: null,
    status: null,
    taluka: null,
    sortDirection: -1,
    videoStatus: VIDEOS_STATUS.PENDING,
    isNoVideo: false,
    tags: null,
    ratings: null,
    comments: "",
    product:null,
  });

  // do not change

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState({});
  const [countsLoading, setCountsLoading] = useState(false);
  const [count, setCount] = useState();

  const fetchList = () => {
    setLoading(true);
    dispatch(
      callApiAction(
        async () => await fetchApi({ ...filters }),
        (response) => {
          console.log("FETCH LIST DATA", response);
          setList(response);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchList();
  }, [filters]);

  return (
    <>
      <ListUi
        count={count}
        setCount={setCount}
        countsLoading={countsLoading}
        setCountsLoading={setCountsLoading}
        title={title}
        filters={filters}
        setFilters={setFilters}
        loading={loading}
        list={list}
        columns={columns}
      />
    </>
  );
};
export default ListVideoController;
