export const COMPANY_NAME = "Wehear"

export const companyDetails = {
    NAME: "Wehear",
    EMAIL: "",
    ADDRESS: '',
    GST: "",
    bankName: "",
    bankIFSC: "",
    bankNo: "",
    companyName: COMPANY_NAME

}
export const THIRD_PART_APPS_USED = {
    none: "none",


}
export const USER_ROLES = {
    ADMIN: 1,
    IMPACT_ANALYSIS_TEAM: 2,
    REGISTRATION_TEAM: 3,
    TESTING_TEAM: 4,
    VALIDATION_TEAM: 5,
    EVENT_TEAM: 6,
    SIT_MANAGER: 7,
    SIT_TEAM: 8,
    GOVERMENT: 9,
    DONOR: 10,
    NGO: 11,
    TEACHER: 12,
    // BRC: 13,

    CAMP_COORDINATOR: 14


}
export const NOTIFICATION_TYPE = {
    general: "general",

}
export const SNACK_BAR_VARIETNS = {
    error: "error",
    suceess: "success",
    warning: "warning"
}


export const actions = {
    'SIGN_IN': 'SIGN_IN',
    'SET_USER': 'SET_USER',
    SIGN_OUT: "SIGN_OUT",
    OPEN_MODAL: 'OPEN_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',

    SET_LIFE_STOCK_DATA: "SET_LIFE_STOCK_DATA",
    START_LIFE_STOCK_LOADING: 'START_LIFE_STOCK_LOADING',

    SET_LEAVE_BALANCE_COUNT_DATA: "SET_LEAVE_BALANCE_COUNT_DATA",
    START_LEAVE_BALANCE_COUNT_LOADING: 'START_LEAVE_BALANCE_COUNT_LOADING',


    SET_HOLIDAY_COUNT_DATA: "SET_HOLIDAY_COUNT_DATA",
    START_HOLIDAY_COUNT_LOADING: 'START_HOLIDAY_COUNT_LOADING',

    SET_NOTIFICATION_DATA: 'SET_NOTIFICATION_DATA',
    NOTIFICATION_READ: 'NOTIFICATION_READ',


    CALL_SNACKBAR_DATA: 'CALL_SNACKBAR_DATA',


    SET_DEVICES_LIST: 'SET_DEVICES_LIST',
    UPDATE_DEVICES_LIST: "UPDATE_DEVICES_LIST"

}

export const DATE_TIME_FILTERS = {

    this_week: "This Week",
    this_month: "This Month",
    this_year: "This Year",
    last_month: "Last Month",
    last_year: "Last Year",
    last_week: "Last Week",
    today: "Today",
    yesterday: "Yesterday"

}

export const REGIONS_TYPE = {
    COUNTRY: 1,
    STATE: 2,
    DISTRICT: 3,
    TALUKA: 4,



};

export const GENDER = {
    MALE: 1,
    FEMALE: 2,
    OTHER: 3
}

export const VIDEOS_TAGS = {
    STARTING_SPEAKING: 1,
    INTERACTION_WITH_PARENTS: 2,
    SOCIALLY_ACCEPTED: 3,

    PLAYING_WITH_OTHER_KIDS: 4,
    PERFORMING_CYCLING: 5,
    PLAYING_SPORTS: 6,
    WHILE_STUDING: 7,
    LISTERNING_TEACHER_IN_CLASS: 8,
    EDUCATE_OTHER_KIDS: 9,
    HELPING_IN_DOMESTIC_WORK: 10,
    ENJOY_SONG_OR_MUSIC: 11,
    PURCHASING_FROM_STORE: 12,
    PERFORMING_DANCE: 13,
    SELF_EMPLOYED: 14,
    TRAVELING_IN_PUBLIC_TRANSPORT: 15,
    OTHER:16
}

export const VIDEOS_STATUS = {
    NULL:0,
    PENDING: 1,
    MANAGER_APPROVE: 2,
    MANAGER_REJECT: 3,
    ADMIN_APPROVE: 4,
    ADMIN_REJECT: 5,
    // REJECTED:6,
    // INREVIEW:7,
}

export const VIDEOS_RATING ={
    1:1,
    2:2,
    3:3,
    4:4,
    5:5,
}
export const VIDEOS_PARENTS ={
    PENDING:1,
    MANAGER_APPROVE:2,
    ADMIN_APPROVE:4,
    REJECTED:3,
    INREVIEW:5,
}
