import { OpenInNew } from "@mui/icons-material"
import { Autocomplete, Button, ButtonBase, ButtonGroup, CircularProgress, Grid, IconButton, ListItem, MenuItem, Paper, Skeleton, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tabs, TextField, Tooltip, Typography, styled } from "@mui/material"
import { Box } from "@mui/system"
import { Fragment, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"



import SubmitButton from "../../components/button/SubmitButton"
import { StyledSearchBar, UserSearchBarNormal } from "../../components/inputs/SearchBar"


import { REGIONS_TYPE, USER_ROLES } from "../../utils/constants"
import DataTable from "../../components/tables/DataTable"
import { findObjectKeyByValue, titleCase, toTitleCase } from "../../utils/helper"
import PaddingBoxInDesktop from "../../components/layouts/PaddingBoxInDesktop"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import { getRegionApi } from "../../apis/region.api"
import TimeRangeSelector from "../../components/layouts/common/TimeRangeSelector"
import { getBeneficiaryApi } from "../../apis/beneficiary.api"
import { getUserApi } from "../../apis/user.api"
import { FOLLOW_UP_STATUS } from "../../utils/beneficiary.constant"
import { getCampApi } from "../../apis/camp.api"
import { getEventApi } from "../../apis/event.api"



const FilterTitleBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }

}))
const FiltersBox = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",

    flexWrap: "wrap",
    [theme.breakpoints.down('md')]: {
        flexDirection: "column"
    }
}))


const ListUi = ({ title, filters, setFilters, list, roles, loading, onCreateBtnClick, columns }) => {
  
    const user = useSelector(state => state.user)

    return (
        <>

            <Box mb={3}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} >
                        <FilterTitleBox>
                            <Typography variant="h5" mb={2} >{title}</Typography>


                        </FilterTitleBox>
                        <FiltersBox mt={3} >


                            <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getBeneficiaryApi({ ...para,searchable:['name', 'surname'] ,sort:"surname",sortDirection:1}) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <MenuItem {...rest}> {option.surname} {option.name}</MenuItem>
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, beneficiary_id: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Beneficiary"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            {user.data.role != USER_ROLES.SIT_TEAM && <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getUserApi({ ...para, role: USER_ROLES.SIT_TEAM }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, assigned_to: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Assigned Person"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>}








                        </FiltersBox>
                        <FiltersBox >


                            <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getCampApi({ ...para }) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, camp: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Camp"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>

                            { <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getEventApi({ ...para}) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem  {...rest}>{option.name}</ListItem>
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, event: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Event*"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>}

                            <PaddingBoxInDesktop pl={2} mb={2} sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}  >
                                <AsyncDropDown


                                    lazyFun={async (para) => { return await getRegionApi({ ...para,type:REGIONS_TYPE.TALUKA}) }}

                                    OptionComponent={({ option, ...rest }) => {
                                        return <ListItem {...rest}>{option.name}</ListItem >
                                    }}
                                    onChange={async (changedVal) => {
                                        setFilters({ ...filters, taluka: changedVal ? changedVal._id : null, })
                                    }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    InputComponent={(params) => <StyledSearchBar placeholder={"Select Taluka*"} {...params} margin="none" />}
                                />
                            </PaddingBoxInDesktop>








                        </FiltersBox>


                    </Box>
                    <Box mb={3}>
                        <Tabs value={filters.tab} onChange={(e, val) => setFilters({ ...filters, tab: val })} sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <Tab label="All" value={0} ></Tab>
                            <Tab label="Today" value={1}></Tab>
                            <Tab label="Past" value={2}></Tab>
                            <Tab label="Tomorrow" value={3}></Tab>
                            <Tab label="Future" value={4}></Tab>
                        </Tabs>
                    </Box>
                    <FiltersBox mb={3} >
                        <ButtonGroup disableElevation variant="outlined">
                            <Button variant={filters.status == null ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, status: null }) }} >All</Button>
                            <Button variant={filters.status == FOLLOW_UP_STATUS.PENDING ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, status: FOLLOW_UP_STATUS.PENDING }) }}>Pending</Button>
                            <Button variant={filters.status == FOLLOW_UP_STATUS.FOLLOWED_UP ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, status: FOLLOW_UP_STATUS.FOLLOWED_UP }) }}>Completed</Button>
                        </ButtonGroup>
                    </FiltersBox>
                    
                    {/* <ButtonGroup>
                        <Button variant={filters.videos == true ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, videos:true}) }}>Uploaded</Button>
                        <Button variant={filters.videos == false ? "contained" : "outlined"} onClick={() => { setFilters({ ...filters, videos:false}) }}>Not Uploaded</Button>
                    </ButtonGroup> */}
                    <Box sx={{ minHeight: "300px" }}  className="table">
                        <DataTable columns={columns} rows={list.result ? list.result : []} count={list.total ?? 0} filters={filters} setFilters={setFilters} loading={loading} />
                    </Box>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi