import {
  Autocomplete,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Fragment, memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import SubmitButton from "../../components/button/SubmitButton";

import CustomInput from "../../components/inputs/CustomInput";
import {
  StyledSearchBar,
  UserSearchBarNormal,
} from "../../components/inputs/SearchBar";
import { closeModal } from "../../store/actions/modalAction";
import {
  REGIONS_TYPE,
  THIRD_PART_APPS_USED,
  USER_ROLES,
} from "../../utils/constants";
import {
  findNameByRole,
  findObjectKeyByValue,
  titleCase,
  toTitleCase,
} from "../../utils/helper";
import AsyncDropDown from "../../components/inputs/AsyncDropDown";
import { getOpenSolarUserApi } from "../../apis/user.api";
import CustomDialog from "../../components/layouts/common/CustomDialog";
import { CenteredBox } from "../../components/layouts/OneViewBox";
import { getRegionApi } from "../../apis/region.api";
import CreatableRegionComponent from "../../components/custom/CreateableRegionComponent";
import {
  FOLLOWUP_TYPE,
  FOLLOW_UP_LOG_TYPE,
  FOLLOW_UP_STATUS,
  HEARING_STATE,
  INCLUSION_STATE,
  INCLUSION_STATE_NAME,
  SPEECH_STATE,
} from "../../utils/beneficiary.constant";
import { MobileDatePicker } from "@mui/x-date-pickers";
import moment from "moment";

const UpdateFollowupStatusUI = ({
  title,
  modalKey,
  isUpdate,
  fields,
  setFields,
  loading,
  onSubmit,
  type,
  params
}) => {
  const { user } = useSelector((state) => state);
  const answerTypes = () => {
    return {
      [FOLLOW_UP_LOG_TYPE.CALL_NO_ANSWERED]: "Call Not Answered",
      [FOLLOW_UP_LOG_TYPE.CALL_RESCHEDULED]: "Call Rescheduled",
      [FOLLOW_UP_LOG_TYPE.CALL_ANSWERED]: "Call Answered",
    };
  };

  return (
    <>
      <CustomDialog
        id={modalKey}
        loading={loading}
        err={fields.err}
        onSubmit={onSubmit}
        title={`${isUpdate ? "Update" : "Create"} ${title}`}
        closeText="Close"
        confirmText={`${isUpdate ? "Update" : "Create"}`}
      >
        {loading ? (
          <CenteredBox>
            <CircularProgress />
          </CenteredBox>
        ) : (
          <>
            <Autocomplete
              disableClearable
              value={fields.status ? answerTypes[fields.status] : null}
              onChange={(e, changedVal) => {
                setFields({
                  ...fields,
                  status: changedVal ? changedVal._id : null,
                });
              }}
              options={[
                {
                  label: "Call Not Answered",
                  _id: FOLLOW_UP_LOG_TYPE.CALL_NO_ANSWERED,
                },
                {
                  label: "Call Rescheduled",
                  _id: FOLLOW_UP_LOG_TYPE.CALL_RESCHEDULED,
                },
                {
                  label: "Call Answered",
                  _id: FOLLOW_UP_LOG_TYPE.CALL_ANSWERED,
                },
              ]}
              label="Select Status"
              sx={{
                width: "100%",
                display: "flex",
                "*": { display: "flex", justifyContent: "center" },
              }}
              renderInput={(params) => (
                <CustomInput
                  label="Select Status"
                  placeholder="Select Status"
                  {...params}
                />
              )}
            />

            {fields.status == FOLLOW_UP_LOG_TYPE.CALL_ANSWERED ? (
              <>
                {fields.type == FOLLOWUP_TYPE.INCLUSIVE ? (
                  <>
                    <Autocomplete
                      disableClearable
                      value={findObjectKeyByValue(
                        fields.inclusion_state,
                        INCLUSION_STATE
                      )}
                      onChange={(e, changedVal) => {
                        setFields({
                          ...fields,
                          inclusion_state: changedVal ? changedVal._id : null,
                        });
                      }}
                      options={Object.keys(INCLUSION_STATE).map((item) => ({
                        _id: INCLUSION_STATE[item],
                        label: INCLUSION_STATE_NAME[item],
                      }))}
                      sx={{
                        width: "100%",
                        display: "flex",
                        "*": { display: "flex", justifyContent: "center" },
                      }}
                      renderInput={(params) => (
                        <CustomInput
                          label="Inclusion Progress*"
                          placeholder="Inclusion Progress*"
                          {...params}
                        />
                      )}
                    />

                    {fields.inclusion_state != INCLUSION_STATE.I3 && (
                      <MobileDatePicker
                        value={moment(fields.date)}
                        inputFormat="DD/MM/YYYY"
                        onChange={(val) => {
                          setFields({
                            ...fields,
                            err: "",
                            date: val.toISOString(),
                          });
                        }}
                        label="Next Followup date*"
                        renderInput={(params) => (
                          <CustomInput
                            placeholder="Next Followup  date*"
                            {...params}
                          />
                        )}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <Autocomplete
                      disableClearable
                      value={findObjectKeyByValue(
                        fields.hearing_state,
                        HEARING_STATE
                      )}
                      onChange={(e, changedVal) => {
                        setFields({
                          ...fields,
                          hearing_state: changedVal ? changedVal._id : null,
                        });
                      }}
                      options={Object.keys(HEARING_STATE).map((item) => ({
                        _id: HEARING_STATE[item],
                        label: titleCase(item),
                      }))}
                      sx={{
                        width: "100%",
                        display: "flex",
                        "*": { display: "flex", justifyContent: "center" },
                      }}
                      renderInput={(params) => (
                        <CustomInput
                          label="Hearing Progress*"
                          placeholder="Hearing Progress*"
                          {...params}
                        />
                      )}
                    />

                    <Autocomplete
                      disableClearable
                      value={findObjectKeyByValue(
                        fields.speech_state,
                        SPEECH_STATE
                      )}
                      onChange={(e, changedVal) => {
                        setFields({
                          ...fields,
                          speech_state: changedVal ? changedVal._id : null,
                        });
                      }}
                      options={Object.keys(SPEECH_STATE).map((item) => ({
                        _id: SPEECH_STATE[item],
                        label: titleCase(item),
                      }))}
                      sx={{
                        width: "100%",
                        display: "flex",
                        "*": { display: "flex", justifyContent: "center" },
                      }}
                      renderInput={(params) => (
                        <CustomInput
                          label="Speech Progress*"
                          placeholder="Speech Progress*"
                          {...params}
                        />
                      )}
                    />
                  </>
                )}

                <Grid container columnSpacing={2}>
                  <Grid item xs={4}>
                    <CustomInput
                      disabled={loading}
                      value={fields.daily_usage}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          err: "",
                          daily_usage: e.target.value,
                        })
                      }
                      min={0}
                      inputProps={{
                        min: 0,
                        max: 24,
                      }}
                      type="number"
                      label={"Average Daily Usage (H)*"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomInput
                      inputProps={{
                        min: 0,
                        max: 7,
                      }}
                      disabled={loading}
                      value={fields.weekly_usage}
                      onChange={(e) =>
                        setFields({
                          ...fields,
                          err: "",
                          weekly_usage: e.target.value,
                        })
                      }
                      type="number"
                      label={"Average Weekly Usage (D)*"}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomInput
                      disabled
                      value={fields.weekly_usage * fields.daily_usage}
                      label={"Average Weekly Usage (H)*"}
                    />
                  </Grid>
                </Grid>

                {user.data.role == USER_ROLES.SIT_TEAM &&( (params.followup_no == 1 ||
                            params.followup_no == 6 ||
                            params.followup_no == 8) || (params.beneficiary_filters.product == "664c37ab2d6e6c47e5877636" || params.beneficiary_filters.product=="6694b9e3cfb8a8bd29c8cc64")) && (
                  <CustomInput
                    disabled={loading}
                    value={fields.videos}
                    onChange={(e) =>
                      setFields({ ...fields, err: "", videos: e.target.value })
                    }
                    type="text"
                    label={"Video Link"}
                  />
                )}

{/* {user.data.role == USER_ROLES.SIT_TEAM && params.beneficiary_filters.product == "65b0eec3d69fd021868ce9fc" && (
                  <CustomInput
                    disabled={loading}
                    value={fields.videos}
                    onChange={(e) =>
                      setFields({ ...fields, err: "", videos: e.target.value })
                    }
                    type="text"
                    label={"Video Link"}
                  />
                )} */}
              </>
            ) : (
              <>  
                <MobileDatePicker
                  value={moment(fields.date)}
                  inputFormat="DD/MM/YYYY"
                  onChange={(val) => {
                    setFields({ ...fields, err: "", date: val.toISOString() });
                  }}
                  label="Reschedule date*"
                  renderInput={(params) => (
                    <CustomInput placeholder="Reschedule date*" {...params} />
                  )}
                />
              </>
            )}
            <CustomInput
              disabled={loading}
              value={fields.remarks}
              onChange={(e) =>
                setFields({ ...fields, err: "", remarks: e.target.value })
              }
              multiline
              rows={3}
              type="text"
              label={"Remarks*"}
            />
          </>
        )}
      </CustomDialog>
    </>
  );
};
export default memo(UpdateFollowupStatusUI);
